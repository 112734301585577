import { isNil, update } from 'ramda'
import React from 'react'
import { Subscribe } from 'unstated'

import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import MoreIcon from '@material-ui/icons/MoreVert'

import SavingsContainer from '../../containers/SavingsContainer'
import { isBootstrapped } from '../../containers/withLocalStorage'

import Layout from '../../components/layout'
import SavingsComponentDataEditors from '../../components/savings-component-data-editors'

import { getFormulaComponents } from '../../formulas'
import {
  findPeriodByYearAndMonth,
  findPeriodItemIndexByTypeAndName,
  getPeriodDisplayText,
  movePeriodItemDataDown,
  movePeriodItemDataUp,
  removePeriodItemData,
} from '../../util'
import withRoot from '../../withRoot'


class EditPeriodAppBarContent extends React.Component {
  state = { moreAnchorEl: null }

  render() {
    const { moreAnchorEl } = this.state
    const showMore = Boolean(moreAnchorEl)

    return (
      <>
        <IconButton
          color="inherit"
          onClick={this.handleMore}
          aria-label="More"
          aria-owns={showMore ? 'more-menu' : undefined}
          aria-haspopup="true"
        >
          <MoreIcon />
        </IconButton>

        <Menu
          id="more-menu"
          anchorEl={moreAnchorEl}
          open={showMore}
          onClose={this.handleMoreClose}
        >
          <MenuItem onClick={this.handleDelete}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>
      </>
    )
  }

  handleMore = e => {
    this.setState({ moreAnchorEl: e.currentTarget })
  }

  handleMoreClose = e => {
    this.setState({ moreAnchorEl: null })
  }

  handleDelete = e => {
    this.setState({ moreAnchorEl: null }, () => {
      this.props.onDelete()
    })
  }
}
EditPeriodAppBarContent.defaultProps = {
  onDelete() {},
}

const EmptyLayout = (props) => <Layout {...props} upwards />

const EditPeriodPage = ({
  location: {
    state,
    pathname
  },
}) => (
    <Subscribe to={[SavingsContainer]}>
      {savingsData => {
        const year = state ? state.year : null;
        const month = state ? state.month : null;

        if (isNil(year) && isNil(month)) {
          return null;
        }

        const periodDisplayText = getPeriodDisplayText(year, month)

        if (!isBootstrapped(savingsData)) {
          return <EmptyLayout title={periodDisplayText} />
        }

        const period = findPeriodByYearAndMonth(
          year,
          isNil(month) ? undefined : month,
          savingsData.state.periods
        )

        if (!period) {
          return <EmptyLayout pathname={pathname} title={periodDisplayText} />
        }

        const savingsComponents = getFormulaComponents(period.data)

        const handleAmountChange = (type, name, newName, value) => {
          const { data } = period
          const lineItem = findPeriodItemIndexByTypeAndName(type, name, data)

          if (lineItem > -1) {
            const newData = update(
              lineItem,
              { ...data[lineItem], type, name: newName, value },
              data
            )

            savingsData.setPeriod(year, month, { ...period, data: newData })
          } else {
            const newLineItem = { type, name: newName, value }
            savingsData.setPeriod(year, month, {
              ...period,
              data: [...period.data, newLineItem],
            })
          }
        }
        const handleItemMoveUp = (type, name) => {
          const { data } = period
          const movePeriodItemUp = movePeriodItemDataUp(type, name, data)

          if (movePeriodItemUp) {
            savingsData.setPeriod(year, month, {
              ...period,
              data: movePeriodItemUp,
            })
          }
        }

        const handleItemMoveDown = (type, name) => {
          const { data } = period
          const movePeriodItemDown = movePeriodItemDataDown(type, name, data)

          if (movePeriodItemDown) {
            savingsData.setPeriod(year, month, {
              ...period,
              data: movePeriodItemDown,
            })
          }
        }

        const handleItemDelete = (type, name) => {
          const { data } = period
          const removePeriodItem = removePeriodItemData(type, name, data)

          if (removePeriodItem) {
            savingsData.setPeriod(year, month, {
              ...period,
              data: removePeriodItem,
            })
          }
        }

        const handlePeriodDelete = () => {
          savingsData.removePeriod(period.year, period.month, () => {
            window.history.go(-1)
          })
        }

        return (
          <Layout
            title={periodDisplayText}
            upwards="/"
            pathname={pathname}
            appBarContent={
              <EditPeriodAppBarContent onDelete={handlePeriodDelete} />
            }
          >
            <Typography css={{ marginTop: 24 }} color="textSecondary">
              Enter category amounts like employer matching, voluntary
              deferrals, extra savings, and expenses.
            </Typography>

            <SavingsComponentDataEditors
              savingsComponents={savingsComponents}
              onAmountChange={handleAmountChange}
              onItemDelete={handleItemDelete}
              onItemMoveUp={handleItemMoveUp}
              onItemMoveDown={handleItemMoveDown}
            />
          </Layout>
        )
      }}
    </Subscribe>
)

export default withRoot(EditPeriodPage)
