import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { find, isNil } from 'ramda'
import React from 'react'
import { Subscribe } from 'unstated'

import grey from '@material-ui/core/colors/grey'

import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import MoveDownIcon from '@material-ui/icons/ArrowDropDown'
import MoveUpIcon from '@material-ui/icons/ArrowDropUp'
import DeleteIcon from '@material-ui/icons/Delete'
import MoreIcon from '@material-ui/icons/MoreVert'

import AmountField from './amount-field'

import SavingsContainer from '../containers/SavingsContainer'

import { SAVINGS_COMPONENTS } from '../constants'

class PeriodDataTableRow extends React.Component {
  state = {
    name: this.props.name,
    amount: this.props.amount,
    error: false,
    moreAnchorEl: null,
  }

  render() {
    const { InputProps } = this.props
    const { name, amount, moreAnchorEl } = this.state
    const showMore = Boolean(moreAnchorEl)

    return (
      <TableRow data-testid={`item-${name}`} data-test-item>
        <TableCell component="th" scope="row" data-test-name>
          <Input
            tabIndex="0"
            css={{ fontSize: 14 }}
            type="text"
            fullWidth={true}
            value={name}
            onChange={this.handleNameChange}
            onBlur={this.handleNameBlur}
            error={this.state.error}
            inputProps={{ 'data-test-input': 'true' }}
            {...InputProps}
          />
        </TableCell>
        <TableCell align="right" padding="none">
          <AmountField
            TextFieldProps={{ tabIndex: 1 }}
            value={amount}
            onChange={this.handleAmountChange}
          />
        </TableCell>
        <TableCell padding="none" align="right">
          {this.props.showMore && (
            <IconButton
              color="inherit"
              onClick={this.handleMore}
              aria-label="More"
              aria-owns={showMore ? 'more-menu' : undefined}
              aria-haspopup="true"
              data-testid="menu-more"
            >
              <MoreIcon />
            </IconButton>
          )}
          <Menu
            id="more-menu"
            anchorEl={moreAnchorEl}
            open={showMore}
            onClose={this.handleMoreClose}
          >
            <MenuItem
              onClick={this.handleDelete}
              data-testid="menu-option-delete"
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={this.handleMove(this.props.onMoveUp)}
              data-testid="menu-option-move-up"
            >
              <ListItemIcon>
                <MoveUpIcon />
              </ListItemIcon>
              <ListItemText>Move Up</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={this.handleMove(this.props.onMoveDown)}
              data-testid="menu-option-move-down"
            >
              <ListItemIcon>
                <MoveDownIcon />
              </ListItemIcon>
              <ListItemText>Move Down</ListItemText>
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
    )
  }

  handleNameChange = e => {
    const value = e.target.value

    if (value === '') {
      this.setState({ error: true, name: e.target.value })
    } else {
      this.setState({ error: false, name: e.target.value })
    }
  }

  handleNameBlur = e => {
    // no effective change in value
    if (this.props.name === e.target.value) {
      return this.setState({ error: false })
    }

    if (this.state.error) {
      return this.setState({ error: true })
    } else {
      this.setState({ error: false }, () => {
        this.props.onChange(this.props.name, this.state.name, this.state.amount)
      })
    }
  }

  handleAmountChange = amount => {
    this.setState({ amount }, () => {
      this.props.onChange(this.props.name, this.state.name, amount)
    })
  }

  handleMore = e => {
    this.setState({ moreAnchorEl: e.currentTarget })
  }

  handleMoreClose = e => {
    this.setState({ moreAnchorEl: null })
  }

  handleDelete = () => {
    this.setState({ moreAnchorEl: null }, () => {
      this.props.onDelete(this.props.name)
    })
  }

  handleMove = callback => e => {
    this.setState({ moreAnchorEl: null }, () => {
      callback(this.props.name)
    })
  }
}
PeriodDataTableRow.defaultProps = {
  name: '',
  amount: 0,
  showMore: true,
  InputProps: {},
  onChange: (name, newName, amount) => {},
  onDelete: name => {},
  onMoveUp: name => {},
  onMoveDown: name => {},
}

class PeriodDataEditor extends React.Component {
  state = { newItemText: '', newItemAmount: 0 }

  render() {
    const { data } = this.props

    return (
      <Table
        css={css`
          width: 100%;
        `}
        data-testid={`category-${this.props.type}`}
        data-test-category
      >
        <TableBody>
          {data.map(row => (
            <PeriodDataTableRow
              key={`${row.type}-${row.name}`}
              name={row.name}
              amount={row.value}
              onChange={this.handlePeriodRowChange}
              onDelete={this.handlePeriodRowDelete}
              onMoveUp={this.handlePeriodRowMoveUp}
              onMoveDown={this.handlePeriodRowMoveDown}
            />
          ))}
          <Subscribe to={[SavingsContainer]}>
            {savingsData => {
              const missingDefaultLineItems = savingsData.state.defaultComponents[
                this.props.type
              ].filter(
                defaultLineItem =>
                  !find(d => d.name === defaultLineItem.name, data)
              )

              return missingDefaultLineItems.map(lineItem => (
                <PeriodDataTableRow
                  key={`${this.props.type}-${lineItem.name}`}
                  name={lineItem.name}
                  amount={0}
                  onChange={this.handlePeriodRowChange}
                  showMore={false}
                  InputProps={{
                    css: {
                      fontSize: 14,
                      color: grey[400],
                      fontStyle: 'italic',
                    },
                  }}
                />
              ))
            }}
          </Subscribe>
          <PeriodDataTableRow
            key={`new-${this.state.newItemText}-${this.state.newItemAmount}`}
            name={this.state.newItemText}
            amount={this.state.newItemAmount}
            onChange={this.handleNewItemChange}
            showMore={false}
            InputProps={{ placeholder: 'Add line item...' }}
          />
        </TableBody>
      </Table>
    )
  }

  handlePeriodRowChange = (name, newName, amount) => {
    this.props.onAmountChange(this.props.type, name, newName, amount)
  }

  handleNewItemChange = (_, newName, amount) => {
    const { type } = this.props

    if (!isNil(newName) && newName !== '' && amount > 0) {
      this.setState({ newItemText: '', newItemAmount: 0 }, () =>
        this.props.onAmountChange(type, newName, newName, amount)
      )
    } else {
      this.setState({
        newItemText: newName,
        newItemAmount: amount,
      })
    }
  }

  handlePeriodRowDelete = name => {
    this.props.onItemDelete(this.props.type, name)
  }

  handlePeriodRowMoveUp = name => {
    this.props.onItemMoveUp(this.props.type, name)
  }

  handlePeriodRowMoveDown = name => {
    this.props.onItemMoveDown(this.props.type, name)
  }
}
PeriodDataEditor.defaultProps = {
  type: '',
  data: [],
  onAmountChange(type, name, newName, amount) {
    return
  },
  onItemDelete(type, name) {},
  onItemMoveUp(type, name) {},
  onItemMoveDown(type, name) {},
}

const dataWrapperCss = css`
  margin-top: 24px;
  overflow-x: auto;
`

const DataHeader = styled('div')`
  margin: 16px;
`

const DataEditor = ({ heading, description, color, ...props }) => (
  <Paper
    css={css`
      ${dataWrapperCss};
      border-top: 3px solid ${color};
    `}
  >
    <DataHeader>
      <Typography variant="h6" gutterBottom>
        {heading}
      </Typography>
      <Typography variant="caption">{description}</Typography>
    </DataHeader>
    <PeriodDataEditor {...props} />
  </Paper>
)

const SavingsComponentDataEditors = ({ savingsComponents, ...props }) => (
  <>
    {Object.entries(SAVINGS_COMPONENTS).map(
      ([componentName, metadata]) =>
        savingsComponents[componentName].length > 0 && (
          <DataEditor
            key={componentName}
            {...metadata}
            type={componentName}
            data={savingsComponents[componentName]}
            {...props}
          />
        )
    )}
  </>
)

export default SavingsComponentDataEditors
