import { css } from '@emotion/core'
import Dinero from 'dinero.js'
import React from 'react'

import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'

import { toAmount } from '../util'

const DEFAULT_FORMAT = '0'
const DEFAULT_VALUE = DEFAULT_FORMAT

class AmountField extends React.Component {
  state = {
    inputValue: Dinero({ amount: this.props.value }).toFormat(DEFAULT_FORMAT),
    value: this.props.value,
  }

  render() {
    return (
      <TextField
        data-test-amount
        value={
          this.state.inputValue === DEFAULT_VALUE
            ? undefined
            : this.state.inputValue
        }
        placeholder={
          this.state.inputValue === DEFAULT_VALUE ? DEFAULT_VALUE : undefined
        }
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onTouchEnd={this.handleOnTouchEnd}
        css={css`
          width: 75px;

          input {
            text-align: right;

            ::-webkit-outer-spin-button,
            ::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            -moz-appearance: textfield;
          }
        `}
        type="number"
        inputProps={{ 'data-test-input': true }}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        {...this.props.TextFieldProps}
      />
    )
  }

  handleOnTouchEnd = e => {
    // user tapped on field on mobile device
    // so we select the whole range so it's easier to
    // overwrite and user's don't need to tap and hold, etc.
    if (e.target && e.target.value) {
      e.target.select()
    }
  }

  handleBlur = e => {
    const inputValue = e.target.value === '' ? '0' : e.target.value
    const parsedValue = parseFloat(inputValue)

    if (!Number.isNaN(parsedValue)) {
      const amount = toAmount(parsedValue)

      this.setState(
        {
          inputValue: Dinero({ amount }).toFormat(DEFAULT_FORMAT),
          value: amount,
        },
        () => {
          this.props.onChange(amount)
        }
      )
    }
  }

  handleChange = e => {
    this.setState({ inputValue: e.target.value })
  }
}
AmountField.defaultProps = {
  TextFieldProps: {},
  value: 0,
  onChange() {
    return
  },
}

export default AmountField
